import React, {useRef} from "react";
import playBtn from '../../assets/play-btn.png';
import audio1 from '../../assets/British-Nature-Lover.mp3'
import audio2 from '../../assets/Valley-Girl.mp3';
import BannerAudio from '../../assets/banner-img.png';

const Banner = () => {
  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);

  const handlePlayAudio1 = () => {
    if (audioRef2.current) {
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0; // Reset to start
    }
    if (audioRef1.current) {
      audioRef1.current.play();
    }
  };

  const handlePlayAudio2 = () => {
    if (audioRef1.current) {
      audioRef1.current.pause();
      audioRef1.current.currentTime = 0; // Reset to start
    }
    if (audioRef2.current) {
      audioRef2.current.play();
    }
  };
  return(
    <>
      <div className="bg-black text-white min-h-lvh h-full flex items-start md:items-center p-8 pt-20 md:p-20">
        <div className="w-full flex flex-col items-center justify-between h-full wrapper !mt-16">
          <div className="md:w-1/2 mb-14 md:mb-0 flex flex-col items-center justify-center">
            <h1 className="text-5xl mb-4 font-poppins font-semibold text-center">Tell Better Stories of Your Life</h1>
            <p className="text-xl mb-6 font-poppins font-normal text-center">Instantly create voice narration on any of your videos.</p>
            <a href="https://apps.apple.com/ph/app/narrai/id6472699955" target="_blank" className="bg-blue-500 text-white py-4 px-8 rounded-full hover:bg-blue-600 transition-colors font-poppins font-medium" rel="noreferrer">
            Download for iOS
            </a>
          </div>
          <div className="w-full md:w-1/2 relative h-max md:h-screen flex items-center justify-center z-20 -mb-96">
            <div className="relative w-full">
              <img src={BannerAudio} alt="iPhone mockup 1" className="h-full w-full" />
                <div className="absolute bottom-36 md:bottom-32 lg:bottom-48 xl:bottom-80 left-32 md:left-24 lg:left-36 xl:left-52 2xl:left-60 transform -translate-x-1/2 w-16 md:w-12 xl:w-auto lg:max-w-20">
                  <button onClick={handlePlayAudio1}><img className="h-full w-full md:h-auto md:w-auto" src={playBtn} alt="play audio" /></button>
                  <audio ref={audioRef1}>
                    <source src={audio1} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div className="absolute bottom-40 md:bottom-1/2 right-12 lg:right-24 xl:right-32 2xl:right-40 transform -translate-x-1/2 w-16 md:w-12 xl:w-auto lg:max-w-20">
                  <button onClick={handlePlayAudio2}><img className="h-full w-full md:h-auto md:w-auto" src={playBtn} alt="play audio" /></button>
                  <audio ref={audioRef2}>
                    <source src={audio2} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner;