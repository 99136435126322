import React from 'react';
import UploadIcon from '../../assets/upload.png';
import SelectIcon from '../../assets/select.png';
import DownloadIcon from '../../assets/download.png';

const NarratedVideoSteps = () => {
  const steps = [
    {
      icon: UploadIcon,
      title: "Upload or record a video",
      description: "Select an existing file or use the camera app to film a video for 10 to 15 seconds"
    },
    {
      icon: SelectIcon,
      title: "Select your narrator",
      description: "Choose from our ever expanding selection of voice over persona powered by AI. The script will also be based on the narrator persona."
    },
    {
      icon: DownloadIcon,
      title: "Review and Save",
      description: "Playback your narrated video and optionally add background music. Save on your phone to post."
    }
  ];

  return (
    <div className="bg-transparent text-white p-8 md:p-20 relative z-20 wrapper">
      <h1 className="text-5xl font-bold text-center mb-12 font-poppins font-normal">Easy and fast narrated video on the go</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {steps.map((step, index) => (
          <div key={index} className="bg-black rounded-3xl p-12 flex flex-col items-center text-center">
            <div className="bg-custom-black rounded-full p-4 mb-6 w-20 h-20 flex items-center justify-center">
              <img src={step.icon} alt="List Icon" />
            </div>
            <h2 className="text-xl font-semibold mb-2">{step.title}</h2>
            <p className="text-gray-400 text-sm">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NarratedVideoSteps;