import React, { useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

const GlowingCursor = () => {
  const [{ xy }, api] = useSpring(() => ({ 
    xy: [0, 0],
    config: { mass: 1, tension: 300, friction: 80 }
  }));
  useEffect(() => {
    const handleMouseMove = (event) => {
      api.start({ xy: [event.clientX, event.clientY] });
    };

    // Initialize the cursor position to the current mouse position on page load
    const initializeCursorPosition = () => {
      const event = new MouseEvent('mousemove', {
        clientX: window.innerWidth / 2,
        clientY: window.innerHeight / 2,
      });
      handleMouseMove(event);
    };

    window.addEventListener('mousemove', handleMouseMove);
    initializeCursorPosition();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [api]);

  return (
    <div className='cursor' style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: 1,
    }}>
      <animated.div
        className="hidden lg:block"
        style={{
          position: 'absolute',
          filter: 'blur(150px)',
          width: '300px',
          height: '300px',
          borderRadius: '100%',
          background: 'rgba(255, 255, 255, 0.6)',
          transform: xy.to((x, y) => `translate3d(${x - 150}px, ${y - 150}px, 0)`),
          willChange: 'transform',
        }}
      />
    </div>
  );
};

export default GlowingCursor;
