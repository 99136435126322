import React from 'react';
import Img1 from '../../assets/img-box-1.png';
import Img2 from '../../assets/img-box-2.png';

const ImageTextBox = () => {
  return (
    <>
      <div className="bg-transparent text-white mb-12 p-8 md:p-20 flex flex-col md:flex-row relative z-20 wrapper">
        <div className="md:w-1/2 pr-8 flex flex-col justify-center">
          <h2 className="text-3xl font-semibold mb-4 font-poppins">
            Instantly add context in voice for standout content
          </h2>
          <p className="text-custom-grey mb-4 font-poppins">
            Transform any text or visual media into captivating, spoken
            narratives, enhancing the overall impact and accessibility of
            the content. Whether for educational purposes, marketing, or
            entertainment, users can effortlessly provide additional context,
            emotions, and clarity, bringing their stories to life. This feature
            not only enriches the user experience but also ensures that
            content stands out in a crowded digital landscape, making it
            more memorable and effective.
          </p>
        </div>
        <div className="md:w-1/2">
          <div className="bg-transparent rounded-lg overflow-hidden flex items-center justify-end">
            <img 
              src={Img1}
              alt="Person using mobile device with earphones" 
              className="object-cover"
            />
          </div>
        </div>
      </div>

      <div className="bg-transparent text-white p-8 md:p-20 flex flex-col-reverse md:flex-row relative z-20 wrapper">
        <div className="md:w-1/2">
          <div className="bg-transparent rounded-lg overflow-hidden flex items-center justify-start">
            <img 
              src={Img2}
              alt="Person using mobile device with selfie" 
              className="object-cover"
            />
          </div>
        </div>
        <div className="md:w-1/2 md:pl-8 flex flex-col justify-center items-start">
          <h2 className="text-3xl font-bold mb-4">
            Instantly add context in voice for standout content
          </h2>
          <p className="text-gray-400 mb-4 text-left">
            Transform any text or visual media into captivating, spoken
            narratives, enhancing the overall impact and accessibility of
            the content. Whether for educational purposes, marketing, or
            entertainment, users can effortlessly provide additional context,
            emotions, and clarity, bringing their stories to life. This feature
            not only enriches the user experience but also ensures that
            content stands out in a crowded digital landscape, making it
            more memorable and effective.
          </p>
        </div>
      </div>
    </>
  );
};

export default ImageTextBox;