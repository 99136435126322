import React from 'react';
import Narrator1 from '../../assets/narrator-1.png';
import Narrator2 from '../../assets/narrator-2.png';
import Narrator3 from '../../assets/narrator-3.png';
import Narrator4 from '../../assets/narrator-4.png';
import Narrator5 from '../../assets/narrator-5.png';

const Narrators = () => {
  const narrators = [
    { name: 'British Nature Lover', image: Narrator1 },
    { name: 'Voice of God', image: Narrator2 },
    { name: 'Movie Trailer Guy', image: Narrator3 },
    { name: 'Valley Girl', image: Narrator4 },
    { name: 'Unhappy Chef', image: Narrator5 },
  ];

  return (
    <div className="bg-transparent text-white min-h-screen flex flex-col items-center justify-center p-8 md:p-20 wrapper">
      <div className="w-full relative z-20">
        <h1 className="text-4xl font-normal mb-6 text-center font-poppins">Narrators</h1>
        <p className="text-center mb-12 font-poppins font-normal text-md text-custom-grey">
          Get ready for a wild ride as we embark on an epic adventure with five distinct characters vying to narrate your video, each promising to
          inject a hefty dose of fun into the mix!
        </p>
        <div className="flex flex-col md:flex-row justify-between">
          {narrators.map((narrator, index) => (
            <div key={index} className="flex flex-col items-center mb-10 md:mb-0">
              <img 
                src={narrator.image} 
                alt={narrator.name} 
                className="w-auto h-auto rounded-full mb-4"
              />
              <p className="text-sm text-center">{narrator.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Narrators;