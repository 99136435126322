import React from "react";
import Banner from '../../components/Banner';
import ImageTextBox from '../../components/ImageTextBox';
import NarrateWithAI from '../../components/NarrateWithAI';
import NarratedVideoSteps from '../../components/NarratedVideoSteps';
import Narrators from '../../components/Narrators';

const Homepage = () => {
  return(
    <>
      <Banner />
      <NarrateWithAI />
      <NarratedVideoSteps />
      <ImageTextBox />
      <Narrators />
    </>
  )
}

export default Homepage;