import React from 'react';

const NarrateWithAI = () => {
  return (
    <div className="bg-transparent text-white h-max md:min-h-screen flex flex-col items-center justify-center py-20 px-8 md:p-4 relative z-20 wrapper">
      <div className="max-w-2xl w-full text-center mt-20">
        <h2 className="text-sm mb-2 font-poppins font-bold text-custom-grey">What is Narrai?</h2>
        <h1 className="text-4xl font-bold mb-6 font-poppins font-normal">Narrate with AI</h1>
        <p className="font-poppins font-normal leading-7 text-custom-grey">Narrai simplifies adding relevant voiceovers for videos in a simple delightful flow.</p>
        <p className="mb-8 font-poppins font-normal leading-7 text-custom-grey">
          Whether for personal, social or business, Narrai generates a unique script, voice generation and background music
          merged for posting or saving.
        </p>
        <a href="https://apps.apple.com/ph/app/narrai/id6472699955" target="_blank" className="bg-red-500 text-white text-sm px-12 py-4 rounded-full hover:bg-red-600 transition-colors font-poppins font-medium" rel="noreferrer">
          Download
        </a>
      </div>
    </div>
  );
};

export default NarrateWithAI;