import React from 'react';
import Logo from '../../assets/logo.png';
import footerImg from '../../assets/footer.png';
import appstoreLogo from '../../assets/appstore.png';

const Footer = () => {
  return (
    <>
      <div className="bg-black text-white min-h-screen flex items-center justify-center p-8 py-20 mb-0 md:mb-0 md:p-20 ">
      <div className="max-w-6xl w-full flex flex-col md:flex-row items-center justify-between relative z-20 wrapper">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <div className="flex items-center mb-6">
            <img src={Logo} alt="Site logo" />
          </div>
          <h1 className="text-5xl font-bold mb-4">Narrate Amazing Instantly</h1>
          <p className="text-xl mb-6">Make your videos viral with Narrai</p>
          <a href="https://apps.apple.com/ph/app/narrai/id6472699955" target="_blank" className="bg-transparent text-black rounded-lg flex items-center mb-8" rel="noreferrer">
            <img src={appstoreLogo} alt="Apple logo" className="mr-2" />
          </a>
          <div className="flex space-x-4">
            {/* <a href="/" className="text-red-500 hover:text-red-400">
              <span className="sr-only">Facebook</span>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="black" stroke="#FF2C55"/>
              <path d="M23.9986 21.2494L24.4981 17.63H21.3757V15.2812C21.3757 14.2913 21.8118 13.3256 23.2103 13.3256H24.6296V10.2444C24.6296 10.2444 23.3418 10 22.1101 10C19.5389 10 17.8583 11.7337 17.8583 14.8719V17.6306H15V21.25H17.8583V30H21.3757V21.25L23.9986 21.2494Z" fill="#FF2C55"/>
              </svg>
            </a>
            <a href="/" className="text-red-500 hover:text-red-400">
              <span className="sr-only">LinkedIn</span>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="black" stroke="#FF2C55"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M17.3911 12.7073C17.3911 13.6344 16.6396 14.3859 15.7126 14.3859C14.7856 14.3859 14.0341 13.6344 14.0341 12.7073C14.0341 11.7803 14.7856 11.0288 15.7126 11.0288C16.6396 11.0288 17.3911 11.7803 17.3911 12.7073ZM18.976 24.9705V15.6581H21.7515V16.9316H21.7907C22.1765 16.2005 23.1201 15.428 24.5279 15.428C27.4599 15.428 28 17.3558 28 19.8644V24.9713H25.107V20.442C25.107 19.3627 25.0886 17.9734 23.6027 17.9734C22.096 17.9734 21.8674 19.1502 21.8674 20.3653V24.9705H18.976ZM17.1601 15.6581H14.2642V24.9705H17.1601V15.6581Z" fill="#FF2C55"/>
              </svg>

            </a> */}
            <a href="mailto:narraiapp@gmail.com" className="text-red-500 hover:text-red-400">
              <span className="sr-only">Email</span>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="black" stroke="#FF2C55"/>
              <path d="M11.75 13.8125C11.3719 13.8125 11.0625 14.1219 11.0625 14.5V15.4496L18.4746 21.534C19.3641 22.2645 20.6402 22.2645 21.5297 21.534L28.9375 15.4496V14.5C28.9375 14.1219 28.6281 13.8125 28.25 13.8125H11.75ZM11.0625 18.118V25.5C11.0625 25.8781 11.3719 26.1875 11.75 26.1875H28.25C28.6281 26.1875 28.9375 25.8781 28.9375 25.5V18.118L22.8359 23.1281C21.1859 24.4816 18.8098 24.4816 17.1641 23.1281L11.0625 18.118ZM9 14.5C9 12.9832 10.2332 11.75 11.75 11.75H28.25C29.7668 11.75 31 12.9832 31 14.5V25.5C31 27.0168 29.7668 28.25 28.25 28.25H11.75C10.2332 28.25 9 27.0168 9 25.5V14.5Z" fill="#FF2C55"/>
              </svg>
            </a>
            <a href="https://tiktok.com/narraiapp" className="text-red-500 hover:text-red-400">
              <span className="sr-only">TikTok</span>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="black" stroke="#FF2C55"/>
              <path d="M26.1647 15.7531C25.3641 15.5777 24.6398 15.1541 24.0958 14.543C23.5518 13.932 23.2161 13.165 23.1368 12.3521V12H20.3658V22.9392C20.3664 23.4246 20.2136 23.8978 19.9292 24.2919C19.6448 24.686 19.2432 24.981 18.7812 25.135C18.3192 25.289 17.8203 25.2943 17.3551 25.1501C16.8899 25.0059 16.4821 24.7195 16.1893 24.3316C15.9271 23.9881 15.7665 23.5784 15.7257 23.1488C15.6849 22.7192 15.7656 22.2868 15.9586 21.9005C16.1517 21.5141 16.4493 21.1893 16.818 20.9628C17.1866 20.7362 17.6115 20.617 18.0447 20.6185C18.2842 20.6171 18.5225 20.6522 18.7514 20.7225V17.9217C18.4851 17.8888 18.2166 17.8755 17.9483 17.8817C16.9598 17.908 16.0005 18.2209 15.1878 18.7822C14.3752 19.3434 13.7444 20.1286 13.3728 21.0415C13.0011 21.9545 12.9047 22.9556 13.0955 23.9223C13.2862 24.889 13.7557 25.7793 14.4465 26.4842C15.1544 27.2014 16.0604 27.6926 17.0493 27.8956C18.0381 28.0986 19.0653 28.0041 20.0001 27.6242C20.9349 27.2442 21.7353 26.596 22.2995 25.7618C22.8638 24.9276 23.1664 23.9451 23.1689 22.9392V17.3375C24.2865 18.1329 25.6266 18.5584 27 18.5539V15.8331C26.7302 15.8342 26.461 15.8074 26.1968 15.7531H26.1647Z" fill="#FF2C55"/>
              </svg>

            </a>
            <a href="https://x.com/narraiapp" className="text-red-500 hover:text-red-400">
              <span className="sr-only">X</span>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="19.5" fill="black" stroke="#FF2C55"/>
              <path d="M25.2213 12H27.9803L21.9224 18.7897L29 28H23.4459L19.0973 22.403L14.119 28H11.3599L17.7777 20.738L11 12H16.6921L20.6208 17.1129L25.2213 12ZM24.2556 26.4059H25.7851L15.8884 13.5351H14.2449L24.2556 26.4059Z" fill="#FF2C55"/>
              </svg>

            </a>
          </div>
        </div>
        <div className="md:w-1/2 relative">
          <div className="relative inline-block">
            <img src={footerImg} alt="Footer logo" />
          </div>
        </div>
      </div>
    </div>
    <div className="flex items-center justify-center w-full p-10">
      <p className="text-white font-normal text-xs">Copyright 2024 Narrai All Rights Reserved</p>
    </div>
    </>
  );
};

export default Footer;