import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy pt-32 text-justify font-poppins wrapper text-custom-grey z-10 relative pb-20 px-8 lg:px-32">
      <h1 className="text-white font-poppins text-2xl font-semibold text-center mb-8">Privacy Policy for NarrAI</h1>
      
      <p>At NarrAI, accessible from https://narrai.app/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by NarrAI and how we use it.</p>
      
      <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Information We Collect</h2>
      <p>When you visit our website, we may collect the following types of information:</p>
      <ul>
        <li>Personal information you voluntarily provide to us, such as your name and email address when you sign up for our service or contact us.</li>
        <li>Usage data, including your IP address, browser type, pages visited, and time spent on our website.</li>
        <li>Information collected through cookies and similar technologies.</li>
      </ul>
      
      <h2 className="text-xl font-semibold my-4 text-white">How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our service</li>
        <li>To notify you about changes to our service</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information so that we can improve our service</li>
        <li>To monitor the usage of our service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>
      
      <h2 className="text-xl font-semibold my-4 text-white">Data Protection Rights</h2>
      <p>You have certain data protection rights. NarrAI aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Cookies and Web Beacons</h2>
      <p>Like any other website, NarrAI uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Third-Party Privacy Policies</h2>
      <p>NarrAI's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Children's Information</h2>
      <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. NarrAI does not knowingly collect any Personal Identifiable Information from children under the age of 13.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
      
      <h2 className="text-xl font-semibold my-4 text-white">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us:</p>
      <p>By email: <a href="mailto:narraiapp@gmail.com" className="underline">narraiapp@gmail.com</a></p>
      
      <p>This privacy policy was created for https://narrai.app/ by AppWolfe Inc. If you have any questions or concerns about our privacy policy, please contact us using the information provided above.</p>
    </div>
  );
};

export default PrivacyPolicy;