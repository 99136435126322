import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service pt-32 text-justify font-poppins wrapper text-custom-grey z-10 relative pb-20 px-8 lg:px-32">
      <h1 className="text-white font-poppins text-2xl font-semibold text-center mb-8">Terms of Service</h1>

      <p>Welcome to Narrai.app (the "Service"). These Terms of Service ("Terms") govern your use of our website located at https://narrai.app (the "Site") and any related services provided by AppWolfe (the "Company").</p>

      <p>By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, do not use the Service.</p>

      <h2 className="text-xl font-semibold my-4 text-white">1. Use of Service</h2>

      <div className="pl-8 lg:pl-12 mb-8">
        <h3 className="text-lg font-semibold my-4 text-white">1.1 Eligibility</h3>
        <p>You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you meet this age requirement.</p>

        <h3 className="text-lg font-semibold my-4 text-white">1.2 Account Registration</h3>
        <p>To access certain features of the Service, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

        <h3 className="text-lg font-semibold my-4 text-white">1.3 Account Security</h3>
        <p>You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account. You agree to notify the Company immediately of any unauthorized use of your account.</p>

        
        <ul>
          <li>Using the Service for any illegal purpose or in violation of any local, state, national, or international law.</li>
          <li>Posting or sharing any content that is illegal, harmful, threatening, defamatory, obscene, infringing, or otherwise objectionable.</li>
          <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service.</li>
          <li>Using any automated system, including without limitation "robots," "spiders," "offline readers," etc., to access the Service.</li>
        </ul>
      </div>

      <h2 className="text-xl font-semibold my-4 text-white">2. User Conduct</h2>

      <div className="pl-8 lg:pl-12 mb-8">
        <h3 className="text-lg font-semibold my-4 text-white">2.1 Prohibited Activities</h3>
        <p>You agree not to engage in any of the following prohibited activities:</p>
      </div>

      <h2 className="text-xl font-semibold my-4 text-white">3. Content</h2>

      <div className="pl-8 lg:pl-12 mb-8">
        <h3 className="text-lg font-semibold my-4 text-white">3.1 Your Content</h3>
        <p>You retain ownership of any content you post or share through the Service ("Your Content"). By posting or sharing Your Content, you grant the Company a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, display, reproduce, and distribute Your Content in connection with operating and providing the Service.</p>

        <h3 className="text-lg font-semibold my-4 text-white">3.2 Company's Content</h3>
        <p>All content available on the Service, including but not limited to text, graphics, logos, and software, is the property of the Company or its licensors and is protected by copyright and other intellectual property laws.</p>
      </div>

      <h2 className="text-xl font-semibold my-4 text-white">4. Privacy</h2>
      <p>Your use of the Service is also governed by our Privacy Policy, which is available at [Privacy Policy URL]. By using the Service, you consent to the collection and use of your information as described in the Privacy Policy.</p>

      <h2 className="text-xl font-semibold my-4 text-white">5. Termination</h2>
      <p>The Company may terminate or suspend your account and access to the Service at any time, with or without cause, with or without notice. Upon termination, your right to use the Service will immediately cease.</p>

      <h2 className="text-xl font-semibold my-4 text-white">6. Disclaimers</h2>
      <p>The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied. The Company disclaims all warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

      <h2 className="text-xl font-semibold my-4 text-white">7. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>

      <h2 className="text-xl font-semibold my-4 text-white">8. Changes to Terms</h2>
      <p>The Company reserves the right to modify these Terms at any time. We will provide notice of such changes by posting the revised Terms on the Site. Your continued use of the Service after such changes have been posted shall constitute your acceptance of the revised Terms.</p>

      <h2 className="text-xl font-semibold my-4 text-white">9. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles.</p>

      <h2 className="text-xl font-semibold my-4 text-white">10. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:narraiapp@gmail.com" className="underline pb-0.5 box-content block w-auto">narraiapp@gmail.com</a></p>
    </div>
  );
};

export default TermsOfService;